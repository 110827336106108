import { payLicenseFeeRoutingModule } from './../@web_ui/modules/tradeLicenseModules/tradeLicense/pay-license-fee/pay-license-fee-routing-module';
import { homePageRoutingModule } from './../@web_ui/modules/home-page/home-page-routing.module';
import { SasformModule } from './../@web_ui/modules/property/sasform/sasform/sasform.module';
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CustomLayoutComponent } from "./custom-layout/custom-layout.component";
import { QuicklinkModule, QuicklinkStrategy } from "ngx-quicklink";
import { AuthenticateGuard } from "src/@web_ui/services/guards/authenticate-guard.service";
import { AdminAndMasterAdminGuard } from "src/@web_ui/services/guards/admin-guard.service";
import { MasterAdminGuard } from "src/@web_ui/services/guards/masteradmin-guard.service";
import { FullcomponentComponent } from 'src/@web_ui/modules/layout/fullcomponent/fullcomponent.component';
const routes: Routes = [
  //login route

  {
    path: "login",
    loadChildren: () =>
      import("./../@web_ui/modules/user/login/login.module").then(
        (m) => m.LoginModule
      ),
  },

  {
    path: "",
    component: FullcomponentComponent,
   
    children:[
      {
        path: "",
        redirectTo: "",
        pathMatch: "full",
      },
      {
        path: "",
        loadChildren: () => import('../@web_ui/modules/mainlandingpage/mainlandingpage.module').then(m => m.MainlandingpageModule),
      },
      // {
      //   path: "finddocs",
      //   loadChildren: () =>
      //     import(
      //       "../@web_ui/modules/getforms/findform/findform.module"
      //     ).then((m) => m.FindformModule),
      // },
    ]
  },

  {
    path: "error404",
    loadChildren: () =>
      import("../@web_ui/modules/error-404/error-404.module").then(
        (m) => m.Error404Module
      ),
  },
  
  {
    path: "",
    component: CustomLayoutComponent,
    children: [
      {
        path: "home",
        redirectTo: "/ptms",
        pathMatch: "full",
      },
     
      //properties route
      {
        path: "ptms",
        children: [
          {
            path: "home",
            loadChildren: () =>
              import(
                "../@web_ui/modules/home-page/home-page.module"
              ).then((m) => m.HomePageModule),
            canActivate: [AuthenticateGuard],
          },
          {
            path: "properties",
            loadChildren: () =>
              import(
                "../@web_ui/modules/property/sasform/master-properties/master-properties.module"
              ).then((m) => m.MasterPropertiesModule),
            canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
            data: {
              menuUrl: 'properties',
            }
          },
          {
            path: "deletedProperties",
            loadChildren: () =>
              import(
                "../@web_ui/modules/property/sasform/deleted-assessments/deleted-assessments.module"
              ).then((m) => m.DeletedAssessmentsModule),
            canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
            data: {
              menuUrl: 'deletedProperties',
            }
          },
          {
            path: "sasforms",
            loadChildren: () =>
              import(
                "../@web_ui/modules/property/sasform/home-properties/home-properties.module"
              ).then((m) => m.HomePropertiesModule),
            canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
            data: {
              menuUrl: 'sasforms',
            }
          },
          {
            path: "getsas",
            loadChildren: () =>
              import(
                "../@web_ui/modules/property/sasform/find-sasform/find-sasform.module"
              ).then((m) => m.FindSasformModule),
            canActivate: [AuthenticateGuard, AdminAndMasterAdminGuard],
            data: {
              menuUrl: 'getsas',
            }
          },
          {
            path: "newsasform",
            loadChildren: () =>
              import(
                "../@web_ui/modules/property/sasform/generate-sasform/generate-sasform.module"
              ).then((m) => m.GenerateSasformModule),
            canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
            data: {
              menuUrl: 'newsasform',
            }
          },
          {
            path: "saspayment",
            loadChildren: () =>
              import(
                "../@web_ui/modules/property/sasform/saspayment/saspayment.module"
              ).then((m) => m.SaspaymentModule),
            canActivate: [AuthenticateGuard],
            data: {
              menuUrl: 'newsasform',
            }
          },
          {
            path: "sassaved",
            loadChildren: () =>
              import(
                "../@web_ui/modules/property/sasform/sasform/sasform.module"
              ).then((m) => m.SasformModule),
              canActivate: [AuthenticateGuard],
          },
          {
            path: "formpreview",
            loadChildren: () =>
              import(
                "../@web_ui/modules/property/sasform/sas-preview/sas-preview.module"
              ).then((m) => m.SasPreviewModule),
              canActivate: [AuthenticateGuard],
             
          },

          //genrate qr code
          {
            path: "qrcode",
            loadChildren: () =>
              import(
                "../@web_ui/modules/property/genrateqrcode/genrateqrcode.module"
              ).then((m) => m.GenrateqrcodeModule),
            canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
            data: {
              menuUrl: 'qrcode',
            }
          },
          
          {
            path: "menus",
            loadChildren: () =>
              import(
                "../@web_ui/modules/menus/menus/home-menus/home-menus.module"
              ).then((m) => m.HomeMenusModule),
             canActivate: [AuthenticateGuard, AdminAndMasterAdminGuard], 
             data: {
              menuUrl: 'menus',
            }
          },

          //users route

          {
            path: "users",
            loadChildren: () =>
              import("../@web_ui/modules/user/users/home/home.module").then(
                (m) => m.HomeModule
              ),
              canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
              data: {
                menuUrl: 'users',
              }
          },
          // roles
          {
            path: "roles",
            loadChildren: () =>
              import("../@web_ui/modules/roles/home/home.module").then(
                (m) => m.HomeModule
              ),
             canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
             data: {
              menuUrl: 'roles',
            }
          },
          //state route

          {
            path: "state",
            loadChildren: () =>
              import("../@web_ui/modules/state/home/home.module").then(
                (m) => m.HomeModule
              ),
              canActivate: [AuthenticateGuard, AdminAndMasterAdminGuard],
              data: {
                menuUrl: 'state',
              }
          },
          // city Route

          {
            path: "ctv",
            loadChildren: () =>
              import("../@web_ui/modules//ctv/home/home.module").then(
                (m) => m.HomeModule
              ),
              canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
              data: {
                menuUrl: 'ctv',
              }
          },
          // district route
          {
            path: "district",
            loadChildren: () =>
              import("../@web_ui/modules/district/home/home.module").then(
                (m) => m.HomeModule
              ),
              canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
              data: {
                menuUrl: 'district',
              }
          },

          //register route
          {
            path: "register",
            loadChildren: () =>
              import("../@web_ui/modules/user/register/register.module").then(
                (m) => m.RegisterModule
              ),
              canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
              data: {
                menuUrl: 'register',
              }
          },

          // types routing
          // ownership type

          {
            path: "ownershiptype",
            loadChildren: () =>
              import("../@web_ui/modules/ownershiptype/home/home.module").then(
                (m) => m.HomeModule
              ),
              canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
              data: {
                menuUrl: 'ownershiptype',
              }
          },
          //propertyusetype route

          {
            path: "propertyusetype",
            loadChildren: () =>
              import(
                "../@web_ui/modules/propertyusetype/home/home.module"
              ).then((m) => m.HomeModule),
              canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
              data: {
                menuUrl: 'propertyusetype',
              }
          },

          // type of road route
          {
            path: "typeofroad",
            loadChildren: () =>
              import(
                "../@web_ui/modules/typeofroad/home-tor/home-tor.module"
              ).then((m) => m.HomeTorModule),
              canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
              data: {
                menuUrl: 'typeofroad',
              }
          },

          //propertyusage
          {
            path: "propertyusage",
            loadChildren: () =>
              import("../@web_ui/modules/propertyusage/home/home.module").then(
                (m) => m.HomeModule
              ),
              canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
              data: {
                menuUrl: 'propertyusage',
              }
          },
          //type of construction
          {
            path: "typeofconstruction",
            loadChildren: () =>
              import(
                "../@web_ui/modules/typeofconstruction/home/home.module"
              ).then((m) => m.HomeModule),
              canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
              data: {
                menuUrl: 'typeofconstruction',
              }
          },
          {
            path: "devices",
            loadChildren: () =>
              import(
                "../@web_ui/modules/usersdevices/userdevices/userdevices.module"
              ).then((m) => m.UserdevicesModule),
              canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
              data: {
                menuUrl: 'devices',
              }
          },
          {
            path: "devicedetails",
            loadChildren: () =>
              import(
                "../@web_ui/modules/usersdevices/devicedetails/devicedetails.module"
              ).then((m) => m.DevicedetailsModule),
              canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
              data: {
                menuUrl: 'devicedetails',
              }
          },
          {
            path: "userprofile",
            loadChildren: () =>
              import(
                "../@web_ui/modules/user/social/social.module"
              ).then((m) => m.socialModule
              ),
            canActivate: [AuthenticateGuard],
          },


          {
            path: "mapmenus",
            loadChildren: () =>
              import(
                "../@web_ui/modules/menus/map-menus/map-menus.module"
              ).then((m) => m.MapMenusModule),
             canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
             data: {
              menuUrl: 'mapmenus',
            }
          },

          {
            path: "routes",
            loadChildren: () =>
              import(
                "../@web_ui/modules/routes/route/home-routes/home-routes.module"
              ).then((m) => m.HomeRoutesModule),
             canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
             data: {
              menuUrl: 'routes',
            }
          },
          {
            path: "routesaccess",
            loadChildren: () =>
              import(
                "../@web_ui/modules/routes/route-access/route-access.module"
              ).then((m) => m.RouteAccessModule),
             canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
             data: {
              menuUrl: 'routesaccess',
            }
          },
          {
            path: "circle",
            loadChildren: () =>
              import(
                "../@web_ui/modules/property/circleandwards/circle/circle-home/circle-home.module"
              ).then((m) => m.CircleHomeModule),
             canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
             data: {
              menuUrl: 'circle',
            }
          },
          {
            path: "wards",
            loadChildren: () =>
              import(
                "../@web_ui/modules/property/circleandwards/wards/home-ward/home-ward.module"
              ).then((m) => m.HomeWardModule),
             canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
             data: {
              menuUrl: 'wards',
            }
          },
          {
            path: "landtypes",
            loadChildren: () =>
              import(
                "../@web_ui/modules/property/landtype/landtypes/landtypes.module"
              ).then((m) => m.LandtypesModule),
             canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
             data: {
              menuUrl: 'landtypes',
            }
          },
          {
            path: "occupancyfactors",
            loadChildren: () =>
              import(
                "../@web_ui/modules/property/occupancyfactors/occupancyfactor/occupancyfactor.module"
              ).then((m) => m.OccupancyfactorModule),
             canActivate: [AuthenticateGuard, AdminAndMasterAdminGuard],
             data: {
              menuUrl: 'occupancyfactors',
            }
          },
          {
            path: "tarrifstructure",
            loadChildren: () =>
              import(
                "../@web_ui/modules/property/tariffstructure/tariif-structure/tariif-structure.module"
              ).then((m) => m.TariifStructureModule),
             canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
             data: {
              menuUrl: 'tarrifstructure',
            }
          },
          {
            path: "datasettings",
            loadChildren: () =>
              import(
                "../@web_ui/modules/property/databasesettings/databasesettings/databasesettings.module"
              ).then((m) => m.DatabasesettingsModule),
             canActivate: [AuthenticateGuard, AdminAndMasterAdminGuard],
             data: {
              menuUrl: 'datasettings',
            }
          },
          {
            path: "vltaxstructures",
            loadChildren: () =>
              import(
                "../@web_ui/modules/property/vltaxratestructure/vltaxrate/vltaxrate.module"
              ).then((m) => m.VltaxrateModule),
             canActivate: [AuthenticateGuard, AdminAndMasterAdminGuard],
             data: {
              menuUrl: 'vltaxstructures',
            }
          },
          {
            path: "years",
            loadChildren: () =>
              import(
                "../@web_ui/modules/property/years/home-year/home-year.module"
              ).then((m) => m.HomeYearModule),
             canActivate: [AuthenticateGuard, AdminAndMasterAdminGuard],
             data: {
              menuUrl: 'years',
            }
          },
          {
            path: "floorsindex",
            loadChildren: () =>
              import(
                "../@web_ui/modules/property/floors/home-floor/home-floor.module"
              ).then((m) => m.HomeFloorModule),
             canActivate: [AuthenticateGuard, AdminAndMasterAdminGuard],
             data: {
              menuUrl: 'floorsindex',
            }
          },

          {
            path: "penality",
            loadChildren: () =>
              import(
                "../@web_ui/modules/property/penality/home-penality/home-penality.module"
              ).then((m) => m.HomePenalityModule),
             canActivate: [AuthenticateGuard, AdminAndMasterAdminGuard],
             data: {
              menuUrl: 'penality',
            }
          },
          {
            path: "roadsDetails",
            loadChildren: () =>
              import(
                "../@web_ui/modules/property/roadDetails/road-details/road-details.module"
              ).then((m) => m.RoadDetailsModule),
             canActivate: [AuthenticateGuard, AdminAndMasterAdminGuard],
             data: {
              menuUrl: 'roadsDetails',
            }
          },
          {
            path: "dailycollection",
            loadChildren: () =>
              import(
                "../@web_ui/modules/property/reports/dailycollection/dailycollection.module"
              ).then((m) => m.DailycollectionModule),
            canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
            data: {
              menuUrl: 'dailycollection',
            }
          },
          {
            path: "pmwcollection",
            loadChildren: () =>
              import(
                "../@web_ui/modules/property/reports/pmwcollection/pmwcollection.module"
              ).then((m) => m.PmwcollectionModule),
            canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
            data: {
              menuUrl: 'pmwcollection',
            }
          },



          // Trade License Routes
          {
            path: "taxationTypes",
            loadChildren: () =>
              import(
                "../@web_ui/modules/tradeLicenseModules/taxationType/home-taxationtype/home-taxationtype.module"
              ).then((m) => m.HomeTaxationtypeModule),
            canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
            data: {
              menuUrl: 'taxationTypes',
            }
          },
          {
            path: "categoryTypes",
            loadChildren: () =>
              import(
                "../@web_ui/modules/tradeLicenseModules/categoryTypeTL/home-category-type/home-category-type.module"
              ).then((m) => m.HomeCategoryTypeModule),
            canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
            data: {
              menuUrl: 'categoryTypes',
            }
          },
         
          {
            path: "nonResidentialusages",
            loadChildren: () =>
              import(
                "../@web_ui/modules/tradeLicenseModules/nonResidentialTypeTL/home-nonresidential/home-nonresidential.module"
              ).then((m) => m.HomeNonresidentialModule),
            canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
            data: {
              menuUrl: 'nonResidentialusages',
            }
          },
          {
            path: "firmTypes",
            loadChildren: () =>
              import(
                "../@web_ui/modules/tradeLicenseModules/firmTypeTL/home-firm-type/home-firm-type.module"
              ).then((m) => m.HomeFirmTypeModule),
            canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
            data: {
              menuUrl: 'firmTypes',
            }
          },
          {
            path: "applyTradeLicense",
            loadChildren: () =>
              import(
                "../@web_ui/modules/tradeLicenseModules/tradeLicense/trade-license/trade-license.module"
              ).then((m) => m.TradeLicenseModule),
            canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
            data: {
              menuUrl: 'applyTradeLicense',
            } 
          },
          {
            path: "reUploadImages",
            loadChildren: () =>
              import(
                "../@web_ui/modules/tradeLicenseModules/tradeLicense/upload-images/upload-images.module"
              ).then((m) => m.UploadImagesModule),
            canActivate: [AuthenticateGuard],
            data: {
              menuUrl: 'reUploadImages',
            } 
          },
          {
            path: "uploadImages",
            loadChildren: () =>
              import(
                "../@web_ui/modules/tradeLicenseModules/tradeLicense/trade-license-documents/trade-license-documents.module"
              ).then((m) => m.TradeLicenseDocumentsModule),
            canActivate: [AuthenticateGuard],
            data: {
              menuUrl: 'uploadImages',
            } 
          },
          {
            path: "searchLicense",
            loadChildren: () =>
              import(
                "../@web_ui/modules/tradeLicenseModules/tradeLicense/view-trade-license/view-trade-license.module"
              ).then((m) => m.ViewTradeLicenseModule),
            canActivate: [AuthenticateGuard],
            data: {
              menuUrl: 'searchLicense',
            } 
          },
          {
            path: "payFee",
            loadChildren: () =>
              import(
                "../@web_ui/modules/tradeLicenseModules/tradeLicense/pay-license-fee/pay-license-fee.module"
              ).then((m) => m.PayLicenseFeeModule),
            canActivate: [AuthenticateGuard],
            data: {
              menuUrl: 'payFee',
            } 
          },
          {
            path: "paymentReceipt",
            loadChildren: () =>
              import(
                "../@web_ui/modules/tradeLicenseModules/tradeLicense/payment-receipt/payment-receipt.module"
              ).then((m) => m.PaymentReceiptModule),
            canActivate: [AuthenticateGuard],
            data: {
              menuUrl: 'paymentReceipt',
            } 
          },
          {
            path: "tradeLicenseApplications",
            loadChildren: () =>
              import(
                "../@web_ui/modules/tradeLicenseModules/tradeLicense/home-trade-license/home-trade-license.module"
              ).then((m) => m.HomeTradeLicenseModule),
            canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
            data: {
              menuUrl: 'tradeLicenseApplications',
            } 
          },
          {
            path: "approveTlRequests",
            loadChildren: () =>
              import(
                "../@web_ui/modules/tradeLicenseModules/tradeLicense/approve-tl-requests/approve-tl-requests.module"
              ).then((m) => m.ApproveTlRequestsModule),
            canActivate: [AuthenticateGuard,AdminAndMasterAdminGuard],
            data: {
              menuUrl: 'approveTlRequests',
            } 
          },
          {
            path: "previewLicense",
            loadChildren: () =>
              import(
                "../@web_ui/modules/tradeLicenseModules/tradeLicense/preview-trade-license/preview-trade-license.module"
              ).then((m) => m.PreviewTradeLicenseModule),
            canActivate: [AuthenticateGuard],
            data: {
              menuUrl: 'previewLicense',
            } 
          },
          {
            path: "yearsTL",
            loadChildren: () =>
              import(
                "../@web_ui/modules/tradeLicenseModules/years/yearstl/yearstl.module"
              ).then((m) => m.YearstlModule),
            canActivate: [AuthenticateGuard, AdminAndMasterAdminGuard],
            data: {
              menuUrl: 'yearsTL',
            } 
          },
         
        ],
      },
    ],
  },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: QuicklinkStrategy,
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule, QuicklinkModule],
})
 
export class AppRoutingModule {}