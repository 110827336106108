import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NavigationComponent } from '../navigation/navigation.component';
import { CommonModule } from '@angular/common';
import { MainlandingpageModule } from '../../mainlandingpage/mainlandingpage.module';

@Component({
  selector: 'app-fullcomponent',
  templateUrl: './fullcomponent.component.html',
  styleUrls: ['./fullcomponent.component.scss'],
  standalone: true,
  imports:[RouterModule,CommonModule,],
})
export class FullcomponentComponent {
  @ViewChild('navbarToggle') navbarToggle: ElementRef;
  @ViewChild('navbarMenu') navbarMenu: ElementRef;
  @ViewChild('navbarLinksContainer') navbarLinksContainer: ElementRef;

  constructor(public router: Router, ) {
    this.updateDateTime();
    setInterval(() => {
    this.updateDateTime();
  }, 1000);
  }
  currentDateTime :string;
  updateDateTime(): void {
    const now = new Date();
    this.currentDateTime = now.toLocaleString();
  }
  login(){
    this.router.navigate(['/login']);
  }
  isExpanded = false;

  toggleNavbar() {
    this.isExpanded = !this.isExpanded;
  }
  
  onNavbarMenuClick(event: MouseEvent) {
    event.stopPropagation();
  }
  
  navigateToHome() {
    this.router.navigate(['/']);
}

navigateToProducts() {
    this.router.navigate(['/login']);
}
}

