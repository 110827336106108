import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class JwtintercepterService implements HttpInterceptor {

  constructor(public jwthelperservice:JwtHelperService) {  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var currentUser={token:""};
    var currentUserSession = sessionStorage.getItem('currentUser');
    if(currentUserSession!=null){
      currentUser= JSON.parse(currentUserSession);
    }
    req= req.clone({
      setHeaders:{
        Authorization:"Bearer "+ currentUser.token
      }
    })
    
    // console.log(this.jwthelperservice.decodeToken(currentUser.token))
    return next.handle(req);

  }
     
     
}
