
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";

// @Injectable({
//   providedIn: 'root'
// })
// export class AuthMethods {
//   isMasterA:string;
//   constructor(private jwthelperService:JwtHelperService) { }



//   public isAuthenticated():boolean{
//     if(this.jwthelperService.isTokenExpired())
//     { 
//       return false;
//     }
//     else{
//       return true;
//     }
//   }
//   public isActivated():boolean{  
//    var token= this.jwthelperService.decodeToken();  
//     if(token ==null)
//       return false;
//     if(token.IsActive=="true") {
//       return true;}
//     else
//       return false;
//   }
//   public isMasterAdmin():boolean{

//   var token= this.jwthelperService.decodeToken();  
//   console.log("auth method");
//     if(token ==null)
//       return false;
//     if(token.role=="111")  
//       return true;
//     else
//       return false;
//   }
//   public isAdminAndMasterAdmin():boolean{
   
//     var token= this.jwthelperService.decodeToken();  
//     if(token ==null)
//       return false;
//     if(token.role=="111" || token.role=="181")
//       return true;
//     else
//       return false;
//   }
// }

@Injectable({
  providedIn: 'root'
})
export class AuthMethods {
  constructor(private jwthelperService:JwtHelperService) { }

  public isAuthenticated():boolean{
    if(this.jwthelperService.isTokenExpired())
    { 
      return false;
    }
    else{
      return true;
    }
  }
  public isActivated():boolean{  
   var token= this.jwthelperService.decodeToken();  
  
    if(token ==null)
      return false;
    if(token.IsActiveAuthorization=="true") {
      return true;}
    else
      return false;
  }
  public isMasterAdmin():boolean{

  var token= this.jwthelperService.decodeToken();  
  
    if(token ==null)
      return false;
    if(token.role=="111")  
      return true;
    else
      return false;
  }
  public isAdminAndMasterAdmin():boolean{
   
    var token= this.jwthelperService.decodeToken();  
    if(token ==null)
      return false;
    if(token.role=="111" || token.role=="181")
      return true;
    else
      return false;
  }
}
