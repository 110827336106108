import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import Swal from "sweetalert2";

import { AuthMethods } from "../auth-methods";

@Injectable({
  providedIn: "root",
})
export class AuthenticateGuard implements CanActivate {
  constructor(private service: AuthMethods, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (!this.service.isAuthenticated()) {
      this.router.navigateByUrl("login");
      return false;
    }
    if (!this.service.isActivated()) {
      this.router.navigateByUrl("login");
      return false;
    } else return true;
  }
}
