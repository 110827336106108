import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ConfigsService {
  //private baseUrl = 'https://localhost:7138/ptms';
  private baseUrl = 'https://nagarpanchayatkarakat.in/api';
  constructor(private router :Router) {  }

  getUrl(){
    return this.baseUrl;
  }
}
