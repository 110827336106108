<div class="top-Nav">
    <div class="col-6">
    </div>
    <div class="col-6 datetime">
      {{currentDateTime}}
    </div>
</div>


<div class="navbar"> 
    <div class="col-1">
      <img src="assets/img/npkLogo.jpg" alt="homepage" class="navbar-logo" />
    </div>
    <div class="col-7 heading" >
     <p> 
      NAGAR PANCHAYAT KARAKAT <br/> नगर पंचायत काराकाट
      </p>
    </div>   
    <div  class="col-4 navbar-links sm:flex-row sm:gap-2" [class.show]="isExpanded">
      <a class="navbar-link" href="">Home</a>
      <!-- <a class="navbar-link" href="/finddocs">Search Property </a> -->
      <a class="navbar-link" href="/login">Official Login</a>
    </div>
      <button class="navbar-expand-button" (click)="toggleNavbar()">
        <span></span>
        <span></span>
        <span></span>
      </button>
  </div>
<router-outlet></router-outlet>
