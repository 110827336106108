import { Injectable } from "@angular/core";
import {
  NavigationDropdown,
  NavigationItem,
  NavigationLink,
  NavigationSubheading,
} from "../interfaces/navigation-item.interface";
import { Observable, Subject } from "rxjs";
import { Userrole } from "src/@web_ui/models/userrole";
import { UserroleService } from "src/@web_ui/services/userrole.service";
import { MappingMenus } from "src/@web_ui/models/mapping-menus";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  userrole: Userrole;

  items: NavigationItem[] = [];

  private _openChangeSubject = new Subject<NavigationDropdown>();
  openChange$ = this._openChangeSubject.asObservable();

  constructor(
    public userroleService: UserroleService,
    private httpClient: HttpClient
  ) {}

  triggerOpenChange(item: NavigationDropdown) {
    this._openChangeSubject.next(item);
  }

  isLink(item: NavigationItem): item is NavigationLink {
    return item.type === "link";
  }

  isDropdown(item: NavigationItem): item is NavigationDropdown {
    return item.type === "dropdown";
  }

  isSubheading(item: NavigationItem): item is NavigationSubheading {
    return item.type === "subheading";
  }
  
}
