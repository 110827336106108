import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MappingMenus } from "../models/mapping-menus";
import { Observable } from "rxjs";
import {
  NavigationDropdown,
  NavigationItem,
  NavigationLink,
  NavigationSubheading,
} from "src/@vex/interfaces/navigation-item.interface";
import { ConfigsService } from "./config.service";

@Injectable({
  providedIn: "root",
})
export class MappingmenusService {
  items: NavigationItem[] = [];
  constructor(private httpClient: HttpClient, private configService: ConfigsService) {}

  createMappingMenus(selectedMenuIds: any[]): Observable<any> {
    return this.httpClient.post<any>(
      `${this.configService.getUrl()}/mappingmenusandroles/createandupdate`,
      selectedMenuIds
    );
  }

  getMappingMenus(roleCode: string): Observable<any> {
    const encodedCode = btoa(roleCode);
    const params = new HttpParams().set("roleCode", encodedCode);
    return this.httpClient.get<any>(
      `${this.configService.getUrl()}/mappingmenusandroles/getMappedMenus`,
      { params }
    );
  }
  getMappingMenu(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.configService.getUrl()}/mappingmenusandroles`
    );
  }
}
