import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable, catchError, map, of, switchMap, tap } from "rxjs";
import Swal from "sweetalert2";
import { MappingmenusService } from "../mappingmenus.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import * as CryptoJS from "crypto-js";
import { UserService } from "../user.service";

@Injectable({
  providedIn: "root",
})
export class AdminAndMasterAdminGuard implements CanActivate {
  mappedMenus: string[];
  menuUrl: string;
  private secretKey = "Ptms_Data@001234#Menus"; 
  constructor(
    private jwthelperService: JwtHelperService,
    private router: Router,
    private mappingmenuService: MappingmenusService,
    private userService : UserService,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const token = this.jwthelperService.decodeToken();

    if (!token || !token.role) {
      this.showUnauthorizedMessage();
      return of(false);
    }

    const tokenRole = token.role;
    return this.userService.getUsersByClaims().pipe(
      switchMap((response: any) => {
        const matchedRole = response.roles.find(
          (role: any) => role.roleCode === tokenRole
        );
        if (matchedRole && matchedRole.roleLevel === 4) {
          return of(true);
        } else {
          this.menuUrl = route.data["menuUrl"];
          return this.getMenusAndCheckAccess(state.url);
        }
      }),
      catchError(() => {
        this.router.navigateByUrl("/error-404");
        return of(false);
      })
    );
  }

  private getMenusAndCheckAccess(
    requestedRoute: string
  ): Observable<boolean | UrlTree> {
    const token = this.jwthelperService.decodeToken();

    if (!token || !token.role) {
      this.showUnauthorizedMessage();
      return of(false);
    }

    const selectedrole = token.role;
    const encryptedMenus = localStorage.getItem("matchedData");

    if (encryptedMenus) {
      const menus = this.decryptData(encryptedMenus);
      return this.checkAccess(menus, requestedRoute);
    } else {
      return this.mappingmenuService.getMappingMenus(selectedrole).pipe(
        tap((menus) => {
          const encryptedData = this.encryptData(menus);
          localStorage.setItem("matchedData", encryptedData);
        }),
        switchMap((menus) => this.checkAccess(menus, requestedRoute)),
        catchError((err) => {
          this.router.navigateByUrl("/error-404");
          return of(false);
        })
      );
    }
  }

  private checkAccess(
    menus: any[],
    requestedRoute: string
  ): Observable<boolean | UrlTree> {
    if (!menus || menus.length === 0) {
      this.router.navigateByUrl("/error-404");
      return of(false);
    }

    const mappedUrls = this.extractUrls(menus);
    const foundMatch = mappedUrls.includes(this.menuUrl);

    if (foundMatch) {
      return of(true);
    } else {
      sessionStorage.clear();
      localStorage.clear();
      this.router.navigateByUrl("/error404");
      return of(false);
    }
  }

  private extractUrls(menus: any[]): string[] {
    let mappedUrls: string[] = [];
    menus.forEach((menu) => {
      mappedUrls.push(menu.url);
      if (menu.subMenus && menu.subMenus.length > 0) {
        mappedUrls = mappedUrls.concat(this.extractUrls(menu.subMenus));
      }
    });
    return mappedUrls;
  }

  private encryptData(data: any): string {
    return CryptoJS.AES.encrypt(JSON.stringify(data), this.secretKey).toString();
  }

  private decryptData(encryptedData: string): any {
    const bytes = CryptoJS.AES.decrypt(encryptedData, this.secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  private showUnauthorizedMessage() {
    Swal.fire({
      icon: "error",
      title: "Unauthorized",
      text: "You are unauthorized. Please login to access this page.",
    });
  }
}