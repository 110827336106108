import { ConfigsService } from './config.service';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, map } from "rxjs";
import { User } from "../models/user";
import { Authenticate } from "../models/authenticate";
import { ChangePasswordVM } from '../models/change-password-vm';
import { RoleAccess } from '../models/role-access';

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private router: Router, private httpClient: HttpClient, private configService:ConfigsService) {}

  registerUser(roleCode:string,user: User): Observable<any> {
    const encodedCode = btoa(roleCode);
    const params = new HttpParams().set("roleCode", encodedCode);
    return this.httpClient.post<any>(
      `${this.configService.getUrl()}/users/register`,
      user,{params}
    );
  }
  checkUser(authenticate: Authenticate): Observable<any> {
    return this.httpClient
      .post<any>(
        `${this.configService.getUrl()}/users/authenticate`,
        authenticate
      )
      .pipe(
        map((u) => {
          if (u) {
            sessionStorage["currentUser"] = JSON.stringify(u);
          }
          return null;
        })
      );
  }
  LogOut() {
    // sessionStorage.removeItem('currentUser');
    sessionStorage.clear();
    this.router.navigateByUrl("/");
  }

  //users
  getUsers(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.configService.getUrl()}/users`
    );
  }
  getUsersByClaims(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.configService.getUrl()}/users/userbyclaim`
    );
  }

  deleteUser(userCode: string): Observable<any> {
    const encodedCode = btoa(userCode);
    const params = new HttpParams().set("userCode", encodedCode);
    return this.httpClient.delete<any>(
      `${this.configService.getUrl()}/users/delete`,
      { params }
    );
  }

  updateUsers(user: User): Observable<any> {
    return this.httpClient.put<any>(
      `${this.configService.getUrl()}/users/updateuser`,
      user
    );
  }

  updatePassword(userCode : string, changeVM: ChangePasswordVM): Observable<any> {
    const encodedCode = btoa(userCode);
    const params = new HttpParams().set("userCode", encodedCode);
    return this.httpClient.put<any>(
      `${this.configService.getUrl()}/users/updatepassword`,
      changeVM,{params} 
    );
  }

  getUser(userCode: string): Observable<any> {
    const encodedCode = btoa(userCode);
  const params = new HttpParams().set("userCode", encodedCode);
    return this.httpClient.get<any>(
      `${this.configService.getUrl()}/users/user`,
      { params }
    );
  }

  activeUser(userCode: string): Observable<any> {
    const encodedCode = btoa(userCode);
  const params = new HttpParams().set("userCode", encodedCode);
    return this.httpClient.put<any>(
       `${this.configService.getUrl()}/users/activedeactiveuser?${params.toString()}`,
      {} 
    );
  }

  getUsersByRole(roleCode: string): Observable<any> {
   const encodedCode = btoa(roleCode);
  const params = new HttpParams().set("roleCode", encodedCode);
    return this.httpClient.get<any>(
      `${this.configService.getUrl()}/users/usersbyrole`,
      { params }
    );
  }
  getUsersByRoleCode(roleCode: string): Observable<any> {
   const encodedCode = btoa(roleCode);
  const params = new HttpParams().set("roleCode", encodedCode);
    return this.httpClient.get<any>(
      `${this.configService.getUrl()}/users/users`,
      { params }
    );
  }
  changeUserRole(code: string): Observable<any> {
    const encodedCode = btoa(code);
    const url = `${this.configService.getUrl()}/users/changeRole?roleCode=${encodedCode}`;
    return this.httpClient.post<any>(
      url,
      {}
    ).pipe(
      map((u) => {
        if (u) {
          localStorage.clear();
          sessionStorage.clear();
          sessionStorage["currentUser"] = JSON.stringify(u);
        }
        return null;
      })
    );
  }

  getUsersByCtv(ctvCode: string): Observable<any> {
   const encodedCode = btoa(ctvCode);
  const params = new HttpParams().set("ctvCode", encodedCode);
    return this.httpClient.get<any>(
      `${this.configService.getUrl()}/users/usersbyctv`,
      { params }
    );
  }

  getUsersByPhone(phoneNumber: string): Observable<any> {
    const encodedCode = btoa(phoneNumber);
    const params = new HttpParams().set("phoneNumber", encodedCode);
    return this.httpClient.get<any>(
      `${this.configService.getUrl()}/users/userbyphone?`,
      { params }
    );
  }
  getRoleAccess(userCode: string): Observable<any> {
    const encodedCode = btoa(userCode);
    const params = new HttpParams().set("userCode", encodedCode);
    return this.httpClient.get<any>(
      `${this.configService.getUrl()}/users/useraccessofroles?`,
      { params }
    );
  }

  updateRoleAccess(selectedRoleIds: any[]): Observable<any> {
    return this.httpClient.post<any>(
      `${this.configService.getUrl()}/users/updateroleaccess`,
      selectedRoleIds
    );
  }
}
