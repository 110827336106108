import { map } from 'rxjs/operators';
import { NavigationDropdown, NavigationItem, NavigationLink, NavigationSubheading } from "./../@vex/interfaces/navigation-item.interface";
import * as CryptoJS from 'crypto-js';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  LOCALE_ID,
  Renderer2,
} from "@angular/core";
import { ConfigService } from "../@vex/config/config.service";
import { Settings } from "luxon";
import { DOCUMENT } from "@angular/common";
import { NavigationService } from "../@vex/services/navigation.service";
import { LayoutService } from "../@vex/services/layout.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SplashScreenService } from "../@vex/services/splash-screen.service";
import { VexConfigName } from "../@vex/config/config-name.model";
import { ColorSchemeName } from "../@vex/config/colorSchemeName";
import {
  MatIconRegistry,
  SafeResourceUrlWithIconOptions,
} from "@angular/material/icon";
import { DomSanitizer, SafeResourceUrl, Title } from "@angular/platform-browser";
import {
  ColorVariable,
  colorVariables,
} from "../@vex/components/config-panel/color-variables";
import { AuthMethods } from "src/@web_ui/services/auth-methods";
import { MappingmenusService } from "src/@web_ui/services/mappingmenus.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Platform } from '@angular/cdk/platform';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DatabasesettingService } from 'src/@web_ui/services/databasesetting.service';
import { DefaultIC } from 'src/@web_ui/models/enummodels';

@Component({
  selector: "vex-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  navigationItems: any[];
  mainMenu: any[];
  selectedrole: string;
  municipalName : any;
  dbSettingsList : any;
  logoImg : any;
  constructor(
    private configService: ConfigService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    public auth: AuthMethods,
    private mappingmenusService: MappingmenusService,
    private jwthelperService: JwtHelperService,
    private cdr: ChangeDetectorRef,
    private titleService : Title,
    private dataService : DatabasesettingService,
  ) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, "is-blink");
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case "mat":
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case "logo":
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case "flag":
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
        }
      }
    );

    this.route.queryParamMap.subscribe((queryParamMap) => {
      if (queryParamMap.has("layout")) {
        this.configService.setConfig(
          queryParamMap.get("layout") as VexConfigName
        );
      }

      if (queryParamMap.has("style")) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get("style") as ColorSchemeName,
          },
        });
      }

      if (queryParamMap.has("primaryColor")) {
        const color: ColorVariable =
          colorVariables[queryParamMap.get("primaryColor")];

        if (color) {
          this.configService.updateConfig({
            style: {
              colors: {
                primary: color,
              },
            },
          });
        }
      }

      if (queryParamMap.has("rtl")) {
        this.configService.updateConfig({
          direction: coerceBooleanProperty(queryParamMap.get("rtl"))
            ? "rtl"
            : "ltr",
        });
      }
    });
  }

  ngOnInit() {
    this.getDatabaseData();
    const encryptedNavigationItems = localStorage.getItem('encrypted');
    if (encryptedNavigationItems) {
      const decryptedNavigationItems = this.decryptData(encryptedNavigationItems);
      this.navigationItems = JSON.parse(decryptedNavigationItems);
      this.navigationService.items = this.navigationItems;
    } else {
      this.loadMenu(); 
    }
  } 
  setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }
  getDatabaseData(){
    this.dataService.getDataValues().subscribe((response) => {
      this.dbSettingsList = response;
      const municipalNames= this.dbSettingsList.find(m => m.defaultIC === DefaultIC.MUNICIPAL);
      this.municipalName = municipalNames ? municipalNames.description:""; 
      this.setTitle(this.municipalName);     
      const logoImg= this.dbSettingsList.find(m => m.defaultIC === DefaultIC.LOGO);
      const logo  = logoImg ? logoImg.description:""; 
      this.logoImg = 'data:image/png;base64,' + logo;
      this.updateFavicon(this.logoImg);    
    }),
      (error) => {
        alert(error.error.message);
      };
  }
  updateFavicon(iconPath: string): void {
    const existingFavicon = this.document.querySelector('link[rel="icon"]');
    
    if (existingFavicon) {
      existingFavicon.setAttribute('href', iconPath);
    } else {
      const linkElement = this.document.createElement('link');
      linkElement.setAttribute('rel', 'icon');
      linkElement.setAttribute('type', 'image/x-icon');
      linkElement.setAttribute('href', iconPath);
      this.document.head.appendChild(linkElement); 
    }
  }

  async loadMenu() {
    const token = this.jwthelperService.decodeToken();

    if (!token || !token.role) {
      return;
    }

    this.selectedrole = token.role;

    try {
      const menuData = await this.mappingmenusService.getMappingMenus(this.selectedrole).toPromise();
      const navigationItems = this.transformMenu(menuData);

      const encryptedNavigationItems = this.encryptData(JSON.stringify(navigationItems));
      localStorage.setItem('encrypted', encryptedNavigationItems);
      this.navigationItems = navigationItems;
      this.navigationService.items = this.navigationItems;
    } catch (error) {
      console.error('Error fetching or transforming menu data:', error);
    }
  }
  encryptData(data: string): string {
    const encrypted = CryptoJS.AES.encrypt(data, 'ptms_propertyTaxManangementSystem_@#01').toString();
    return btoa(encrypted);
  }

  decryptData(data: string): string {
    const decrypted = atob(data);  
    const bytes  = CryptoJS.AES.decrypt(decrypted, 'ptms_propertyTaxManangementSystem_@#01');
    return bytes.toString(CryptoJS.enc.Utf8);
  }


  transformMenu(menuData: any): any[] {
    return menuData.map(menu => {
      const submenuItems = menu.subMenus ? this.transformMenu(menu.subMenus) : [];

      if (submenuItems.length === 0) {
        return {
          type: 'link',
          route: `ptms/${menu.url}`,
          icon: `mat:${menu.icon}`,
          label: menu.name,
          routerLinkActiveOptions: { exact: true }
        };
      } else {
        return {
          type: 'dropdown',
          label: menu.name,
          icon: `mat:${menu.icon}`,
          route: `ptms/${menu.url}`,
          children: submenuItems,
          routerLinkActiveOptions: { exact: true }
        };
      }
    });
  }

}