import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../config/config.service';
import { map, startWith, switchMap } from 'rxjs/operators';
import { NavigationService } from '../../services/navigation.service';
import { PopoverService } from '../../components/popover/popover.service';
// import { MegaMenuComponent } from '../../components/mega-menu/mega-menu.component';
import { Observable, of } from 'rxjs';
import { UserService } from 'src/@web_ui/services/user.service';
import { Userrole } from 'src/@web_ui/models/userrole';
import Swal from "sweetalert2";
import { AppComponent } from 'src/app/app.component';
import { Router } from '@angular/router';
import { DatabasesettingService } from 'src/@web_ui/services/databasesetting.service';
import { DefaultIC } from 'src/@web_ui/models/enummodels';

@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {
  dbSettingsList : any;
  logoImg : any;
  muncipalShortName : any;
  muncipalName : any;
  @Input() mobileQuery: boolean;

  @Input() hasShadow: boolean;
  @HostBinding('class.shadow-b')
  roleName: string;
  roleCode: string;
  selectedRole : string;
  userRoles: Userrole[];
  hasMultipleClaimTypes: any;
  navigationItems = this.navigationService.items;

  isHorizontalLayout$: Observable<boolean> = this.configService.config$.pipe(map(config => config.layout === 'horizontal'));
  isVerticalLayout$: Observable<boolean> = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isNavbarInToolbar$: Observable<boolean> = this.configService.config$.pipe(map(config => config.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$: Observable<boolean> = this.configService.config$.pipe(map(config => config.navbar.position === 'below-toolbar'));
  userVisible$: Observable<boolean> = this.configService.config$.pipe(map(config => config.toolbar.user.visible));

  megaMenuOpen$: Observable<boolean> = of(false);

  constructor(private layoutService: LayoutService,
              private configService: ConfigService,
              private navigationService: NavigationService,
              private popoverService: PopoverService,
              public userService:UserService,
              public app : AppComponent,
              private dataService : DatabasesettingService,
              private router : Router) { }

  openQuickpanel(): void {
    this.layoutService.openQuickpanel();
  }

  openSidenav(): void {
    this.layoutService.openSidenav();
  }
  getDatabaseData(){
    this.dataService.getDataValues().subscribe((response) => {
      this.dbSettingsList = response;
      const municipalShortNames= this.dbSettingsList.find(m => m.defaultIC === DefaultIC.MUNICIPAL);
      this.muncipalShortName = municipalShortNames ? municipalShortNames.name:"";
      const municipalNames= this.dbSettingsList.find(m => m.defaultIC === DefaultIC.MUNICIPAL);
      this.muncipalName = municipalNames ? municipalNames.description:"";
      const logo= this.dbSettingsList.find(m => m.defaultIC === DefaultIC.LOGO);
      const img = logo ? logo.description:"";
      this.logoImg =   'data:image/png;base64,' + img;
    }),
      (error) => {
        alert(error.error.message);
      };
  }
 
  getClaims() {
    this.userService.getUsersByClaims().subscribe((response: any) => {
      if (response && response.roles && Array.isArray(response.roles)) {
        const roles = response.roles;
        const claimTypes = roles.map(role => role.roleLevel);
        const distinctClaimTypes = Array.from(new Set(claimTypes));
        this.hasMultipleClaimTypes = distinctClaimTypes.length > 1;
      } else {
      }
    });    
  }
  getRoles(){
    this.userService.getUsersByClaims().subscribe((data) => {
      this.userRoles = data.roles.map((role) => {
        return { roleName: role.roleName, roleCode: role.roleCode };
      });
      this.userRoles.forEach(role => {
        this.roleCode = role.roleCode;
      });
    });
  }
  onRoleChange(roleCode: string): void {
    this.userService.changeUserRole(roleCode).subscribe((response) => {
      this.app.ngOnInit();
      setTimeout(() => {
        setTimeout(() => {
          window.location.reload(); 
        }, 2000); 
        this.router.navigateByUrl("ptms/home"); 
      }, 2000); 
      this.success();
    },
  ),
    
      (error) => {
        alert(error.error.message);
      };
  }

  ngOnInit() {
  this.getRoles();
  this.getClaims();
  this.getDatabaseData();
    }
 success() {
  Swal.fire({
    toast: true,
    icon: 'success',
    title: 'Role Changed Successfully !!',
    animation: false,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  })
 }


  openSearch(): void {
    this.layoutService.openSearch();
 
  }
  logOutClick(){
    this.userService.LogOut();
  }
}
