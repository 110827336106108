import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigsService } from './config.service';
import { Observable } from 'rxjs';
import { Databasesettings } from '../models/propertyModels/databasesettings';

@Injectable({
  providedIn: 'root'
})
export class DatabasesettingService {

  constructor(private router: Router, private httpClient: HttpClient,private configService: ConfigsService) {}
  getDataValues(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.configService.getUrl()}/datavalue`
    );
  }
  // deleteDataValue(dataUniqueId: string): Observable<any> {
  //   const encodedCode = btoa(dataUniqueId);
  //   const params = new HttpParams().set("dataUniqueId", encodedCode); 
  //      return this.httpClient.delete<any>(
  //       `${this.configService.getUrl()}/cirlceandward/deletecircle?`,
  //     { params }
  //   );
  // }
  getDataValueById(dataUniqueId: string): Observable<any> {
    const encodedCode = btoa(dataUniqueId);
    const params = new HttpParams().set("dataUniqueId", encodedCode); 
       return this.httpClient.get<any>(
        `${this.configService.getUrl()}/datavalue/datavalue`,
      { params }
    );
  }
  createData(settings: Databasesettings): Observable<any> {
    return this.httpClient.post<any>(
      `${this.configService.getUrl()}/datavalue/create`,
      settings
    );
  }
  updateData(settings: Databasesettings): Observable<any> {
    return this.httpClient.put<any>(
      `${this.configService.getUrl()}/datavalue/update`,
      settings
    );
  }
}

