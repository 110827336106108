import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {  NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HTTP_INTERCEPTORS,HttpClientModule } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { JwtModule } from '@auth0/angular-jwt';
import { SidebarModule } from 'src/@vex/components/sidebar/sidebar.module';
import { RouterModule } from '@angular/router';
import { JwtintercepterService } from 'src/@web_ui/services/jwtintercepter.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationModule } from 'src/@vex/layout/navigation/navigation.module';


@NgModule({
  declarations: [AppComponent ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SidebarModule,
    HttpClientModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NavigationModule,
    JwtModule.forRoot({
      config:{
        tokenGetter:()=>{
          return sessionStorage.getItem('currentUser')?
          JSON.parse(sessionStorage.getItem('currentUser')as string).token:null;
        }
      }
    }),
    
    // Vex
    VexModule,
    CustomLayoutModule
  ],
  providers: [
    {
      provide:HTTP_INTERCEPTORS,
      useClass:JwtintercepterService,
      multi:true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
