<div [class.container]="isVerticalLayout$ | async" class="toolbar w-full px-gutter flex items-center">
  <button (click)="openSidenav()" [class.hidden]="!mobileQuery" mat-icon-button type="button">
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>

  <a *ngIf="isVerticalLayout$ | async"
     [routerLink]="['/']"
     class="ltr:mr-4 rtl:ml-4 block flex items-center">
    <img alt="Logo" class="w-8 select-none" [src]="logoImg"/>
    <h1 [class.hidden]="!mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">{{muncipalShortName}}</h1>
  </a>
 <div class="-mx-1 flex items-center">
 </div>
  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
       [class.hidden]="mobileQuery"
       class="px-gutter flex-none flex items-center">
    <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
  </div>

  <h5 class="ml-6"  style="margin-top: 1%; font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">{{muncipalName}}</h5>
  <span class="flex-1"></span>

  <div class="-mx-1 flex items-left">
      <div *ngIf="hasMultipleClaimTypes" class="px-1">
      <button class="btn btn-outline-primary text-white mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base ng-star-inserted" mat-button [matMenuTriggerFor]="menu"> <mat-icon svgIcon="mat:cached"></mat-icon>Change Role</button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item *ngFor="let role of userRoles" (click)="onRoleChange(role.roleCode)">
            {{ role.roleName }}
          </button>
        </mat-menu>
       </div>
        <div *ngIf="userVisible$ | async" class="px-1">
          <vex-toolbar-user></vex-toolbar-user>
        </div>
  </div>
    

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
                [class.hidden]="mobileQuery"></vex-navigation>
  </div>

