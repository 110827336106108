import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, throwError } from "rxjs";
import { Userrole } from "../models/userrole";
import { ConfigsService } from "./config.service";

@Injectable({
  providedIn: "root",
})
export class UserroleService {
  constructor(private httpClient: HttpClient, private configService:ConfigsService) {}

  getRoles(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.configService.getUrl()}/roles`,
    );
  }
  getRole(code: string): Observable<any> {
    const encodedCode = btoa(code);
  const params = new HttpParams().set("roleCode", encodedCode);
    return this.httpClient.get<any>(
      `${this.configService.getUrl()}/roles/role`,
      { params }
    );
  }
  updateRole(role: Userrole): Observable<any> {
    return this.httpClient.put<any>(
      `${this.configService.getUrl()}/roles/update`,
      role
    );
  }

  createRole(userrole: Userrole): Observable<any> {
    return this.httpClient.post<any>(
      `${this.configService.getUrl()}/roles/create`,
      userrole
    );
  }
  deleteRole(roleCode: string): Observable<any> {
    const encodedCode = btoa(roleCode);
  const params = new HttpParams().set("roleCode", encodedCode);  
    return this.httpClient.delete<any>(
      `${this.configService.getUrl()}/roles/delete?`,
      { params }
    );
  }
}
